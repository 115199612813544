<template>
    <div class="bg-opacity-50 bg-white container
            max-w-screen-md mx-auto prose p-4 py-24">
        <h2 class="text-3xl">Join Our Team!</h2>
        <p>
            We at G.A. Corvera Construction Corporation are always looking for people who are willing to learn and grow. Here are some positions we are in need of:
        </p>
        <ul>
            <li>
                <a href="#accountant">Accountant</a>
            </li>
            <li>
                <a href="#engineer">Engineer</a>
            </li>
            <li>
                <a href="#workers">Workers</a>
            </li>
        </ul>

        <h3 id="accountant" class="pt-16">Accountant</h3>
        <p class="font-bold">Requirements:</p>
        <ul>
            <li>
                <span>Licensed Certified Public Accountant</span>
            </li>
            <li>
                <span>At least 2 years of work experience</span>
            </li>
            <li>
                <span>Well-knowledgeable on GAAP (generally accepted accounting principles)</span>
            </li>
            <li>
                <span>Proficient in using Microsoft Office Suite and accounting systems</span>
            </li>
            <li>
                <span>Excellent written and verbal communication skills</span>
            </li>
            <li>
                <span>Responsible for the preparation of financial statements and tax returns</span>
            </li>
            <li>
                <span>Trustworthy</span>
            </li>
            <li>
                <span>Hands-on task-oriented</span>
            </li>
        </ul>
        <p>
            To apply, submit your resume / curriculum vitae to
            <a href="mailto:admin@gacorvera.com">
                admin@gacorvera.com
            </a>
        </p>

        <h3 id="engineer" class="pt-16">Civil Engineer</h3>
        <p class="font-bold">Requirements:</p>
        <ul>
            <li>
                <span>B.S. Civil Engineer (New graduates are encouraged to apply)</span>
            </li>
            <li>
                <span>20-26 years old</span>
            </li>
            <li>
                <span>Capable of reading technical plans</span>
            </li>
            <li>
                <span>Capable of doing estimation</span>
            </li>
            <li>
                <span>Capable of doing Gantt charts</span>
            </li>
            <li>
                <span>Willing to be deployed in different sites at day or night shifts</span>
            </li>
            <li>
                <span>Must be willing to learn</span>
            </li>
            <li>
                <span>Able to meet deadlines</span>
            </li>
            <li>
                <span>Knowledgeable in project management</span>
            </li>
        </ul>
        <p>
            To apply, submit your resume / curriculum vitae to
            <a href="mailto:admin@gacorvera.com">
                admin@gacorvera.com
            </a>
        </p>

        <h3 id="workers" class="pt-16">Skilled Workers</h3>
        <p>Open Positions</p>
        <ul>
            <li>Foreman</li>
            <li>Finishing Carpenter</li>
            <li>Installer</li>
            <li>Painter</li>
            <li>Welder</li>
            <li>Helper</li>
        </ul>
        <p>Bring the following and personally apply at our office. Look for Engr. Mark or Ms. Airish</p>
        <ul>
            <li>Bio-data</li>
            <li>Valid NBI clearance</li>
            <li>Vaccination card</li>
        </ul>
    </div>
</template>

<script>
// Source: https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
import { useRoute } from 'vue-router'
import meta from './../AppMeta.js'

export default {
    data: () => ({
        route: useRoute(),
        meta: {
            title: 'G.A. Corvera Construction Corporation | Careers',
            tags: [
                {
                    property: 'og:title',
                    content: 'G.A. Corvera Construction Corporation | Careers'
                }, {
                    property: 'og:url',
                    content: 'https://gacorvera.com/careers'
                }, {
                    name: 'description',
                    content: 'Contact details of G.A. Corvera Construction Corporation'
                }, {
                    property: 'og:description',
                    content: 'Contact details of G.A. Corvera Construction Corporation'
                }, {
                    property: 'og:image',
                    content: 'https://one.sgp1.cdn.digitaloceanspaces.com/gacorvera/gac-logo-720.png'
                }
            ]
        }
    }),
    mounted() {
        if (this.route.path === '/careers') {
            meta.update(this.meta)
        }
    }
}
</script>
